import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/cva/cva.png"
import cva1 from "../../../assets/images/portfolio_items/cva/cva1.png"
import cva2 from "../../../assets/images/portfolio_items/cva/cva2.png"
import cva3 from "../../../assets/images/portfolio_items/cva/cva3.png"

export default () => 
<div>
    <SEO title={'Crypto ICON Worldwide | Crypto Valley Academy - Blockchain Platform Academy'}
    description="Discover more about Crypto Valley Academy, the blockchain platform totally developed by ICON's team! "
    canonical={'https://icon-worldwide.com/works/crypto-valley-academy'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" Crypto Valley Academy, ICON Worldwide, blockchain platform portfolio" title=" ICON Worldwide portfolio, blockchain platform, Crypto Valley Academy"/>
            <div className="title"><h1>Crypto Valley Academy<span></span></h1>
            <h2>Blockchain development made simple</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>Zug, Switzerland is a unique hub of blockchain and crypto start-ups. The Crypto Valley Academy is a community platform housing education courses from
            universities in Switzerland fusses on blockchain and crypto currencies. ICON developed the brand and platform in Wordpress.</p>
        <div className="portfolio-images" id="long-page">
            <img src={cva1} alt=" Crypto Valley Academy, ICON Worldwide, blockchain platform portfolio" title=" ICON Worldwide portfolio, blockchain platform, Crypto Valley Academy"/>
            <img src={cva3} alt=" Crypto Valley Academy, ICON Worldwide, blockchain platform portfolio" title=" ICON Worldwide portfolio, blockchain platform, Crypto Valley Academy"/>
            <img src={cva2} alt=" Crypto Valley Academy, ICON Worldwide, blockchain platform portfolio" title=" ICON Worldwide portfolio, blockchain platform, Crypto Valley Academy"/>
        </div>
        <WorkFooter previous="elkuch" next="flavorwiki"/>
        </div>
    </div>
</div>